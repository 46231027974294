<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="true" />
    <PageHeader :title="title" />
    <v-card :disabled="fromSeeEnvioConfig">
      <v-form
        v-model="isFormValid"
        ref="send-form"
        id="send-form"
        @submit.prevent="canSaveEnvioConfig()"
        ><v-card-text class="pt-0 pb-0">
          <v-container>
            <v-row class="pr-4">
              <!-- Proceso de envío -->
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-select
                  v-model="procesoEnvioSelected"
                  :items="procesoEnvio"
                  label="Proceso de envío"
                  @change="updateProcesoEnvioDetalle()"
                  :rules="rules.required"
                  item-value="id"
                  item-text="value"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="pr-4">
              <v-col>
                <v-data-table
                  outlined
                  dense
                  hide-default-footer
                  :header-props="{ sortIcon: null }"
                  :items-per-page="50"
                  :headers="configs"
                  class="elevation-1"
                  :items="detalleConfig"
                  fixed-header
                >
                  <template v-slot:[`item.campoValor`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          {{ item.campoValor }}
                          <v-icon
                            :color="'primary'"
                            size="16"
                            @click="openModal(item)"
                            >{{ editIcon }}</v-icon
                          >
                        </div>
                      </template>
                      <span>Editar valor</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-dialog
                  v-if="openModalEdit"
                  v-model="openModalEdit"
                  max-width="400px"
                  @keydown.enter="saveEditValor"
                  persistent
                  @keydown.esc="cancelEditValor"
                >
                  <v-card>
                    <v-container>
                      <v-card-title class="headline primary--text py-0">
                        Editar valor
                      </v-card-title>
                      <v-card-text class="py-0">
                        <v-combobox
                          ref="edit-valor"
                          v-model="campoValorToEdit"
                          :items="selectValor"
                          single-line
                          item-value="id"
                          item-text="value"
                          label="Editar valor"
                        ></v-combobox>
                      </v-card-text>
                      <v-card-actions class="pt-0">
                        <v-spacer></v-spacer>
                        <v-btn outlined @click="cancelEditValor">
                          Cancelar
                        </v-btn>
                        <v-btn color="primary" @click="saveEditValor">
                          Guardar
                        </v-btn>
                      </v-card-actions>
                    </v-container>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row class="pr-4 py-4">
              <!-- Entidad facturante -->
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-select
                  v-model="entidadFacturanteSelected"
                  :items="entidadFacturante"
                  label="Entidad facturante"
                  item-text="value"
                  item-value="id"
                  :rules="rules.required"
                  outlined
                  clearable
                  dense
                >
                </v-select>
              </v-col>
              <!-- Formas de pago -->
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-select
                  outlined
                  clearable
                  dense
                  item-text="value"
                  item-value="id"
                  v-model="formasPagoSelected"
                  label="Formas de pago"
                  :items="formasPago"
                ></v-select
              ></v-col>
              <!-- Entidad de cobranza -->
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-select
                  outlined
                  clearable
                  dense
                  item-text="value"
                  item-value="id"
                  v-model="entidadCobranzaSelected"
                  label="Entidades de cobranza"
                  :items="entidadCobranza"
                ></v-select
              ></v-col>
              <v-col cols="12" md="4" class="py-0 text-right">
                <v-switch
                  class="pt-0"
                  v-model="marcarCompEnviados"
                  label="Marcar comprobantes como enviados"
                  dense
                  outlined
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="submit"
            v-if="this.fromSeeEnvioConfig === false"
            :disabled="!isFormValid"
            form="send-form"
          >
            Grabar configuración
          </v-btn>
        </v-card-actions>
        <ConfirmDialog
          :text="textConfirmDialog"
          :openConfirm.sync="openConfirmDialog"
          @update:openConfirm="cancelar"
          @onConfirm="saveEnvioConfig()"
        />
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: "NuevoEnvioAEntidadCobranza",
  components: {
    PageHeader,
    GoBackBtn,
    ConfirmDialog
  },
  created() {
    if (this.$route.params.envioConfiguracionId) {
      this.title = "Editar configuración de envío a entidad de cobranza";
      this.envioConfigId = this.$route.params.envioConfiguracionId;
      this.setEnvioConfigById();
    }
    if (this.$route.params.seeEnvioConfig) {
      this.fromSeeEnvioConfig = true;
      this.title = "Consulta de configuración de entidad de cobranza";
      this.envioConfigId = this.$route.params.seeEnvioConfig;
      this.setEnvioConfigById();
    }
    this.setSelects();
    this.loadEnvios();
  },
  data: vm => {
    return {
      title: enums.titles.NUEVO_ENVIO_ENTIDAD_COBRANZA,
      editIcon: enums.icons.EDIT,
      selectValor: [],
      routeToGo: "EnviosEntidadesCobranzas",
      procesoEnvioSelected: null,
      procesoEnvio: [],
      envios: [],
      fromSeeEnvioConfig: false,
      isFormValid: false,
      envioConfigId: null,
      formasPagoSelected: null,
      formasPago: [],
      previousValue: null,
      entidadFacturante: [],
      entidadFacturanteSelected: null,
      entidadCobranzaSelected: null,
      entidadCobranza: [],
      tieneErrores: false,
      rules: rules,
      openConfirmDialog: false,
      textConfirmDialog: "",
      sameEntidadFact: null,
      sameFormaPago: null,
      sameEntidadFin: null,
      sameConfiguracion: null,
      configs: [
        {
          text: "Tipo",
          align: "start",
          value: "tipoNombre",
          sortable: false
        },
        { text: "Campo", value: "campoNombre", sortable: false },
        { text: "Valor", value: "campoValor", sortable: false },
        { text: "Descripción", value: "campoDescripcion", sortable: false }
      ],
      detalleConfig: [],
      openModalEdit: false,
      campoValorToEdit: null,
      idToEdit: null,
      marcarCompEnviados: false
    };
  },
  methods: {
    ...mapActions({
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      getEntidadesFinancieras: "devengamientos/getEntidadesFinancieras",
      fetchEnvios: "configuracion/fetchEnvioConfiguracion",
      getFormasDePago: "devengamientos/getFormasDePago",
      getProcesosDetalleById: "configuracion/getProcesosDetalleById",
      getProcesosEnvio: "configuracion/getProcesosEnvio",
      getEnviosConfiguracionById: "configuracion/getEnviosConfiguracionById",
      postEnvioConfiguracion: "configuracion/postEnvioConfiguracion",
      getVariablesEnvios: "configuracion/getVariablesEnvios",
      setAlert: "user/setAlert"
    }),
    openEditDialog(originalValue) {
      this.previousValue = originalValue;
    },
    async setEnvioConfigById() {
      const response = await this.getEnviosConfiguracionById(
        this.envioConfigId
      );
      this.entidadFacturanteSelected = response.entFacId;
      this.entidadCobranzaSelected = response.entFinId;
      this.formasPagoSelected = response.fpagoId;
      this.marcarCompEnviados = response.marcarComprobantesEnviados;
      this.procesoEnvioSelected = response.procesoEnvioId;
      this.detalleConfig = response.procesoEnvioDetalle;
    },
    async setSelects() {
      const response = await this.getEntidadesFacturantes();
      this.entidadFacturante = response;
      const res = await this.getEntidadesFinancieras();
      this.entidadCobranza = res;
      const formasDePago = await this.getFormasDePago();
      this.formasPago = formasDePago;
      const procesosEnvios = await this.getProcesosEnvio();
      this.procesoEnvio = procesosEnvios;
      const selectValor = await this.getVariablesEnvios();
      selectValor.forEach(x => {
        this.selectValor.push(x.value);
      });
    },
    async loadEnvios() {
      const response = await this.fetchEnvios();
      this.envios = response;
    },
    updateProcesoEnvioDetalle() {
      if (
        this.procesoEnvioSelected !== null &&
        this.procesoEnvioSelected !== undefined
      )
        this.setProcesoEnvioDetalle();
    },
    async setProcesoEnvioDetalle() {
      const response = await this.getProcesosDetalleById(
        this.procesoEnvioSelected
      );
      this.detalleConfig = response;
    },
    cancelar() {
      //(this.saveEnvioConfig = null),
      this.textConfirmDialog = "";
    },
    async canSaveEnvioConfig() {
      const data = {
        envioConfiguracionId: this.envioConfigId,
        entFacId: this.entidadFacturanteSelected.id,
        entFinId: this.entidadCobranzaSelected,
        fPagoId: this.formasPagoSelected,
        procesoEnvioId: this.procesoEnvioSelected,
        procesoEnvioDetalle: this.detalleConfig,
        marcarComprobantesEnviados: this.marcarCompEnviados,
        tieneErrores: this.tieneErrores
      };
      this.sameFormaPago = this.envios.find(
        x =>
          x.entFacId == this.entidadFacturanteSelected &&
          this.entidadFacturanteSelected != null &&
          x.fpagoId == this.formasPagoSelected
      );
      this.sameEntidadFin = this.envios.find(
        x =>
          x.entFacId == this.entidadFacturanteSelected &&
          this.entidadFacturanteSelected != null &&
          x.entFinId == this.entidadCobranzaSelected
      );
      if (this.sameFormaPago || this.sameEntidadFin) {
        this.openConfirmDialog = true;
        if (this.sameFormaPago && this.sameFormaPago.formaDePago != null) {
          if (this.textConfirmDialog == "") {
            this.textConfirmDialog = `Ya existe una configuración con forma de pago "${this.sameFormaPago.formaDePago}".`;
          } else {
            this.textConfirmDialog += `Ya existe una configuración con forma de pago "${this.sameFormaPago.formaDePago}".`;
          }
        }
        if (
          this.sameEntidadFin &&
          this.sameEntidadFin.entidadFinanciera != null
        ) {
          if (this.textConfirmDialog == "") {
            this.textConfirmDialog = this.textConfirmDialog = `Ya existe una configuración con la entidad financiera "${this.sameEntidadFin.entidadFinanciera}".`;
          } else {
            this.textConfirmDialog += ` Ya existe una configuración con la entidad financiera "${this.sameEntidadFin.entidadFinanciera}".`;
          }
        }
        if (this.textConfirmDialog != null) {
          let objeto = this.entidadFacturante.find(
            x => x.id == this.entidadFacturanteSelected
          );
          this.textConfirmDialog += ` Para la entidad facturante "${objeto.value}" ¿Desea guardar de todos modos?`;
          objeto = null;
        }
      } else {
        this.tieneErrores = false;
        this.openConfirmDialog = false;
        this.saveEnvioConfig();
      }
    },
    async saveEnvioConfig() {
      const data = {
        envioConfiguracionId: this.envioConfigId,
        entFacId: this.entidadFacturanteSelected,
        entFinId: this.entidadCobranzaSelected,
        fPagoId: this.formasPagoSelected,
        procesoEnvioId: this.procesoEnvioSelected,
        procesoEnvioDetalle: this.detalleConfig,
        marcarComprobantesEnviados: this.marcarCompEnviados,
        tieneErrores: this.tieneErrores
      };
      const response = await this.postEnvioConfiguracion(data);
      if (response.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.$router.push({ name: "EnviosEntidadesCobranzas" });
      }
      this.tieneErrores = false;
    },
    openModal(item) {
      this.campoValorToEdit = item.campoValor;
      this.previousValue = item.campoValor;
      this.idToEdit = item.id;
      this.openModalEdit = true;
    },
    cancelEditValor() {
      const indice = this.detalleConfig.findIndex(x => x.id === this.idToEdit);
      const result = this.detalleConfig.find(x => x.id === this.idToEdit);
      result["campoValor"] = this.previousValue;
      this.detalleConfig.splice(indice, 1, result);
      this.openModalEdit = false;
    },
    saveEditValor() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const indice = this.detalleConfig.findIndex(
          x => x.id === this.idToEdit
        );
        const result = this.detalleConfig.find(x => x.id === this.idToEdit);
        result["campoValor"] = this.campoValorToEdit;
        this.detalleConfig.splice(indice, 1, result);
        this.openModalEdit = false;
        this.setAlert({ type: "success", message: "Actualizado con éxito" });
      }, 100);
    }
  }
};
</script>

<style scope></style>
